import React from "react";
import './footer.css';

const Footer = () => {
    const date = new Date();
    const year = date.getFullYear();

    return (
        <footer id="footer">
            <div className="footer__socialmenu">
                <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/jacobatchison">
                    <img className="footer__socialmenu__logo" src="/footer/images/linkedin.svg" alt="LinkedIn Logo" />
                </a>
                <a rel="noopener noreferrer" target="_blank" href="https://github.com/sipofwater">
                    <img className="footer__socialmenu__logo" src="/footer/images/github.svg" alt="GitHub Logo" />
                </a>
                <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/sipofwater">
                    <img className="footer__socialmenu__logo" src="/footer/images/twitter.svg" alt="Twitter Logo" />
                </a>
            </div>

            <div className="footer__copyright">
                <p>
                    Photography from <a rel="noopener noreferrer" target="_blank" href="https://www.flickr.com/sipofwater">sipofwater</a> on Flickr (that's me!)
                </p>
                <p>
                    &copy;Copyright <span id="year">{ year }</span> by <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/jacobatchison">Jake Atchison</a>
                </p>
            </div>
        </footer>
    );
};
export default Footer;