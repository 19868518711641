import React from "react";
import "./controls.css";
import { FaPlay, FaPause, FaRedoAlt } from "react-icons/fa";

const Controls = ({ isCountingDown, toggleIsCountingDown, reset }) => {
    return (
        <div id="controls" className="controls__container">
            <button
                onClick={ () => { toggleIsCountingDown(isCountingDown) }}
                className="controls__btn"
                id="start_stop"
                type="button"
            >
                { isCountingDown ?
                    <FaPause className="controls__btn__icon"  />
                :
                    <FaPlay className="controls__btn__icon" />
                }
            </button>

            <button
                onClick={ () => { reset() }}
                className="controls__btn"
                id="reset"
                type="button"
            >
                <FaRedoAlt className="controls__btn__icon"  />
            </button>
        </div>
    );
}

export default Controls;