import React from 'react';
import AppRouter from "./routers/AppRouter";
import './App.css';

export default function App() {
    return (
        <AppRouter>
            freecodecamp App
        </AppRouter>
    );
}