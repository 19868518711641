import React from "react";
import "./timercontrols.css";
import Break from "./Break";
import Session from "./Session";

const TimerControls = ({
    sessionLength,
    decreaseSession,
    increaseSession,

    breakLength,
    decreaseBreak,
    increaseBreak
}) => {
    return (
        <div className="timerControls__container">
            <Session
                sessionLength={ sessionLength }
                decreaseSession={ decreaseSession }
                increaseSession={ increaseSession }
            />

            <Break
                breakLength={ breakLength }
                decreaseBreak={ decreaseBreak }
                increaseBreak={ increaseBreak }
            />
        </div>
    );
}

export default TimerControls;