import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { FaHome } from "react-icons/fa";
import './NavBar.css';
import { GreetingToggle, LoginToggle, ModeToggle } from "../session/Session";

const NavBar = () => {
    const { loading } = useAuth0();

    if(loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="navbar__container">
            <HomeButton />
            <ModeToggle />
            <LoginToggle />
            <GreetingToggle />
        </div>
    );
};
export default NavBar;

const HomeButton = () => {
    return (
        <div className="home__icon">
            <Link to={{
                pathname: "/",
                state: { fromDashboard: true }
            }}>
                <FaHome />
            </Link>
        </div>
    );
}