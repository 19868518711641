import React, { Component } from "react";
import marked from "marked";
import './markdown.css';
import { FaEdit, FaEye } from "react-icons/fa";

marked.setOptions({
    breaks: true
});

class Markdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageTitle: "# Markdown Previewer",
            heightEdit: "50",
            widthEdit: "75",
            defaultInput: "Loading default text...",
            value: ""
        }

        this.updateInput = this.updateInput.bind(this);
    }

    componentDidMount() {
        const readmePath = require("./default.md");

        fetch(readmePath)
            .then(response => {
                return response.text()
            })
            .then(text => {
                this.setState({
                    defaultInput: text
                });
            }).catch(err => {
                console.log(`Error in fetching data from server: ${err}`);
            });
    }

    updateInput(event) {
        const newInput = event.target.value;

        this.setState({
            defaultInput: newInput,
            error: ""
        });
    }

    render() {
        const { pageTitle, heightEdit, widthEdit, defaultInput } = this.state;

        return (
            <div id="md">
                <h1 className="md__title">{ pageTitle }</h1>

                <div className="md__container">
                    <div className="md__editor__container">
                        <div className="md__header__container">
                            <span className="md__header__icon"><FaEdit /></span>
                            <span><h1 className="md__header__title">Editor:</h1></span>
                        </div>

                        <textarea
                            type="text"
                            id="editor"
                            cols={ widthEdit }
                            rows={ heightEdit }
                            onChange={ this.updateInput }
                            value={ defaultInput }
                        />
                    </div>
                    <div className="md__preview__container">
                        <div className="md__header__container">
                            <span className="md__header__icon"><FaEye  /></span>
                            <span><h1 className="md__header__title">Preview: </h1></span>
                        </div>
                        <div id="preview"
                            dangerouslySetInnerHTML={{ __html: marked(defaultInput) }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Markdown;