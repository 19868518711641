import React, { Component } from "react";
import './Pomodoro.css';

import TimerControls from "./timercontrols/TimerControls";
import DisplayScreen from "./displayscreen/DisplayScreen";

const initialState = {
    pageTitle: "Pomodoro Clock",

    minutes: 25,
    seconds: 0,
    sessionLength: 25,
    breakLength: 5,

    isCountingDown: false,
    isWorkSession: true
};

class Pomodoro extends Component {
    state = initialState;

    reset = () => {
        if(this.state.isCountingDown) {
            this.stopTimer();
        }

        this.setState(initialState);

        let audio = document.getElementById("beep");
        audio.pause();
        audio.currentTime = 0;
    }

    toggleStatus = () => {
        this.setState({
            isWorkSession: !this.state.isWorkSession
        });
    }

    toggleIsCountingDown = () => {
        const { isCountingDown } = this.state;
        const { startTimer, stopTimer } = this;

        this.setState({ isCountingDown: !isCountingDown });

        (isCountingDown) ? stopTimer() : startTimer();
    }

    stopTimer = () => {
        clearInterval(this.timerId);
    }

    startTimer = () => {
        this.timerId = setInterval(() => {
            const { minutes, seconds, isWorkSession, breakLength, sessionLength } = this.state;
            const { stopTimer, playAudio, toggleStatus, startTimer } = this;

            if (minutes === 0 && seconds === 0) {
                stopTimer();
                playAudio();

                const newCountdown =
                    (isWorkSession)
                    ? breakLength
                    : sessionLength;

                this.setState({
                    minutes: newCountdown
                });

                toggleStatus();
                startTimer();
            } else {
                let newMinutes, newSeconds;

                if(seconds === 0) {
                    newSeconds = 59;
                    newMinutes = minutes - 1;
                } else {
                    newSeconds = seconds -1;
                    newMinutes = minutes;
                }

                this.setState({
                    minutes: newMinutes,
                    seconds: newSeconds
                });
            }
        }, 1000);
    }

    decreaseBreak = () => {
        if (this.state.breakLength > 1) {
            this.setState(prevState => ({
                breakLength: prevState.breakLength - 1
            }));
        }
    }

    increaseBreak = () => {
        if (this.state.breakLength !== 60) {
            this.setState(prevState => ({
                breakLength: prevState.breakLength + 1
            }));
        }
    }

    decreaseSession = () => {
        const { sessionLength } = this.state;

        if (sessionLength > 1) {
            const sessionLength = this.state.sessionLength - 1;

            this.setState({
                sessionLength,
                minutes: sessionLength
            });
        }
    }

    increaseSession = () => {
        const { sessionLength } = this.state;

        if (sessionLength !== 60) {
            const sessionLength = this.state.sessionLength + 1;

            this.setState({
                sessionLength,
                minutes: sessionLength
            });
        }
    }

    playAudio = () => {
        let audio= document.getElementById("beep");
        audio.play();
    }

    render() {
        const {
            sessionLength,
            breakLength,
            isWorkSession,
            minutes,
            seconds,
            isCountingDown
        } = this.state;
        const {
            decreaseSession,
            increaseSession,
            decreaseBreak,
            increaseBreak,
            reset,
            toggleIsCountingDown
        } = this;

        return (
            <div id="pomodoro" className="container">
                <DisplayScreen
                    isWorkSession={ isWorkSession }
                    minutes={ minutes }
                    seconds={ seconds }

                    isCountingDown={ isCountingDown }
                    reset={ reset }
                    toggleIsCountingDown={ toggleIsCountingDown }
                />
                <TimerControls
                    sessionLength={ sessionLength }
                    decreaseSession={ decreaseSession }
                    increaseSession={ increaseSession }

                    breakLength={ breakLength }
                    decreaseBreak={ decreaseBreak }
                    increaseBreak={ increaseBreak }
                />
                <audio
                    id="beep"
                    src={ `https://goo.gl/65cBl1` }
                    className="clip"
                />
            </div>
        );
    }
}

export default Pomodoro;