/*
regarding use of eval():
https://stackoverflow.com/a/6479415/7838125
MDN says it's obsolete!
*/

import React, { Component } from 'react';
import CalcButtons from './CalcButtons';
import DisplayBar from './DisplayBar';
import './Calculator.css';

import { evaluate } from 'mathjs';

const { numbers, operators, memory, others, calcButtons } = require("./calcbtns.json");
const initState = {
    pushedButtons: [],
    lastChar: undefined,
    display: "0",

    wasCalculated: false,

    numbers,
    operators,
    others,

    validInput: [
        ...numbers, 
        ...operators, 
        ...memory,
        ...others
    ]
};

class Calculator extends Component {
    constructor(props) {
        super(props);        
        this.state = { ...initState };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress = ({ key }) => {
        if(key === "Enter") {
            this.calculate();
            return;
        }

        this.processUserInput(key);
    }

    handleOnClick = (event) => {
        this.processUserInput(event.target.value);
    }

    processUserInput = (userInput) => {
        const { pushedButtons, validInput, lastChar } = this.state;
        let { display, wasCalculated } = this.state;

        // wasCalculated = !wasCalculated;

        /* if(wasCalculated) {
            wasCalculated = !wasCalculated;
            display = "";


            this.setState({
                display,
                wasCalculated
            });
        } */

        const isValid = validInput.includes(userInput);
        if(!isValid) return false;

        const isFirst = (lastChar === undefined);
        const isOperator = operators.includes(userInput);
        const isDecimal = userInput === ".";

        /* console.log("lastChar: ", lastChar);
        console.log("isFirst: ", isFirst);
        console.log("isOperator: ", isOperator);
        console.log("display: ", display);
        console.log("display: ", typeof display); */


        if(isOperator) {
            this.handleOperator(userInput);
        }

        if(isDecimal) {
            this.handleDecimal(userInput);
        }


        // REM leading "0", if NOT followed by a "." (ie: 0.[newInput]):
        //if(isFirst) {
//            display = (display.charAt(0) === "0" && display.charAt(1) !== ".")


/*

            display = (display.charAt() === "0" && display.charAt(1) !== ".")
                ? userInput
                : display + userInput
            ;
*/
            display = display + userInput;

        //}

        let newState = {
            display,
            lastChar: userInput,
            pushedButtons: [
                ...pushedButtons,
                userInput
            ],
            wasCalculated
        }

        this.setState({...newState});
    };  // end of processUserInput = (userInput) => {



    handleOperator = (userInput) => {
        const { lastChar } = this.state;
        let { pushedButtons } = this.state;
        let newPushedButtons = [];

        const wasLastCharOperator = operators.includes(lastChar);
        // is new one also operator
        const sameOperator = (lastChar === userInput);

        console.log("OPERATORS:");
        console.log("wasLastCharOperator: ", wasLastCharOperator);
        console.log("sameOperator: ", sameOperator);

        if(wasLastCharOperator && sameOperator) {
            console.log("SAME: leave as is!");
            //const pushedButtonsLast = pushedButtons.length - 1;
            //pushedButtons.length = pushedButtonsLast;
            newPushedButtons = [...pushedButtons];


            // do not add new userInput
        } else {
            // change lastChar to userInput
            console.log("Different: REM last && update to new");

            newPushedButtons = [[...pushedButtons.pop()], userInput];

        }

        console.log("newPushedButtons: ", newPushedButtons);


        ////const newPushedButtons = [...pushedButtons, userInput];

        const display = newPushedButtons.join("");

        console.log("NEW DISPLAY;");
        console.log("display: ", display);
        console.log(pushedButtons);
        console.log(newPushedButtons);

        

        /* display = (operators.includes(lastChar) && userInput !== "-") 
            ? display = display.substring(0, display.length - 1) + userInput
            : display = display + userInput
        ; */

        this.setState({
            display,
            lastChar: userInput,
            pushedButtons: newPushedButtons
        });
    };

    //handleDecimal = (event) => {
    handleDecimal = (userInput) => {
        const { lastChar, operators } = this.state;
        let { pushedButtons, display } = this.state;
        const isFirst = () => lastChar === undefined;
        //const userInput = event.target.value;

        console.log("handleDecimal()");
        console.log("isFirst", isFirst());
        console.log("userInput", userInput);
        console.log("lastChar", lastChar);

        if(lastChar === ".") return false;

        pushedButtons = [...pushedButtons, "."];
        display = display + ".";

        console.log("------->>> Have a happy period!");
        console.log("userInput: ", userInput);
        console.log("display: ", display);
        console.log("pushedButtons: ");
        console.log(this.state.pushedButtons);
        console.log(pushedButtons);

        console.log("*********************");



        console.log("YOU ARE HERE:");
        
        let arrToReversedString = pushedButtons.reverse().join("");
        
        console.log("arrToReversedString: ");
        console.log(arrToReversedString);

        for (let char of arrToReversedString) {

            let operatorIndex = operators.indexOf(char);

            if(operatorIndex !== -1) {
                console.log("WE HAVE A MATCH: ", operators.indexOf(char), char);
                break;
            }
        }

        this.setState({
            lastChar: ".",
            display,
            pushedButtons
        });
    };

    calculate = () => {
        const { pushedButtons, wasCalculated } = this.state;
        let total = "";

        console.log("wasCalculated: ", wasCalculated);

        try {
            total = evaluate(pushedButtons.join("")).toPrecision(4);
//            wasCalculated = true;
//            console.log("TRY wasCalculated: ", wasCalculated);

        }
            catch(err) {
            console.log(err.message);
            total = "Not there yet!";
        }

        console.log("---------");
        console.log("wasCalculated: ", wasCalculated);
        console.log("calculate()");
        console.log(" > :: ", typeof pushedButtons);
        console.log(pushedButtons);

        console.log(total);
        // https://www.w3schools.com/jsref/jsref_replace.asp
//        let total = (pushedButtons.join().replace(/,/g, ""));
        // let total = (pushedButtons.join());

        //console.log(" > :: ", typeof (total));
        //console.log("total: ", total);


//        console.log("try me (evaluate):");
//        console.log(evaluate("2/7"));

        this.setState({
            pushedButtons: [],
            lastChar: undefined,
            display: total,
            wasCalculated: true
        });

        console.log("STATED:_____");
        console.log(this.state);
    }

    // AC: All Clear - allows you to start a new calculation 
    // even if you didn't finish the previous calculation.
    allClear = () => {
        console.log("allClear()");
        this.setState({ ...initState });
    }

    // CE: Clear Entry - clears the number being entered 
    // but leaves in tact all pending operations and numbers.
    clearEntry = () => {
        console.log("clearEntry()");
        // clear last of numbers of input (back to last operator) 

        let updatedPushedButtons = [...this.state.pushedButtons]; // remove last numbers of string

    }

    render() {  
        const { display } = this.state;
        const { 
            handleOnClick, 
            allClear, 
            clearEntry, 
            calculate
        } = this;

        return (
            <div className="calc__container">
                <div id="calculator" >
                    <DisplayBar display={ display } />
                    <CalcButtons
                        arr={ calcButtons }
                        handleOnClick={ handleOnClick }
                        allClear={ allClear }
                        clearEntry={ clearEntry }
                        calculate={ calculate }
                    />
                </div>
            </div>
        );
    };
}

export default Calculator;