import React from "react";
import CustomButton from "../../CustomButton";

const Session = ({ sessionLength, decreaseSession, increaseSession }) => {
    return (
        <div id="session" className="timerControls__session__container">
            <h2 id="session-label"
                className="timerControls__time_header"
            >
                Session
            </h2>

            <CustomButton
                key="session-decrement"
                id="session-decrement"
                title="Decrease Session Length"
                onClick={ decreaseSession }
                className="timerControls__btn"
                content='<i class="arrow-down"></i>'
            />

            <span id="session-length"
                className="timerControls__time__text"
            >
                { sessionLength }
            </span>

            <CustomButton
                key="session-increment"
                id="session-increment"
                title="Increase Session Length"
                onClick={ increaseSession }
                className="timerControls__btn"
                content='<i class="arrow-up"></i>'
            />
        </div>
    );
}

export default Session;