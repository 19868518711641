import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";


import Homepage from "../views/homepage/Homepage";
import Calculator from "../components/calculator/Calculator";
import Drum from "../components/drum/DrumMachine";
import Markdown from "../components/markdown/markdown";
import Pomodoro from "../components/pomodoro/pomodoro";
import Quotes from "../components/quotes/quotes";

import NavBar from "../components/NavBar/NavBar";
//import Footer from "../components/Footer/Footer";
import Footer from "../components/Footer/Footer";
import { useAuth0 } from "../auth/react-auth0-spa";

export default function App() {
    const { loading } = useAuth0();

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <NavBar />

            <Switch>
                <Route exact path="/" component={ Homepage } />
                <Route path="/calc" component={ Calculator } />
                <Route path="/drum" component={ Drum } />
                <Route path="/markdown" component={ Markdown } />
                <Route path="/pomo" component={ Pomodoro } />
                <Route path="/quotes" component={ Quotes } />
            </Switch>

            <Footer />
        </Router>
    )
};
