import React from "react";
import CustomButton from "../CustomButton";

const CalcButtons = ({ 
    arr, 
    handleOnClick, 
    allClear, 
    clearEntry, 
    calculate 
}) => {
    const listItems = arr.map(
        ({ id, isDisabled, desc, onClick, className, content }) => {
            let newOnClick;

            // because function name stored as string in array in .json file
            // crappy way to do this, how to unhardcode values??
            if(onClick === "handleOnClick") newOnClick = handleOnClick;
            if(onClick === "allClear") newOnClick = allClear;
            if(onClick === "clearEntry") newOnClick = clearEntry;
            if(onClick === "calculate") newOnClick = calculate;

        return (
            <CustomButton
                key={ id }
                id={ id }
                title={ content }
                onClick={ newOnClick }
                className={ className }
                content={ content }
                disabled={ isDisabled }          
            />
        );
    });

    return(
        <div className="grid">
            { listItems }
        </div>
    );
}

export default CalcButtons;