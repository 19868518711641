import React from "react";
import CustomButton from "../../CustomButton";

const Break = ({ breakLength, decreaseBreak, increaseBreak }) => {
    return (
        <div id="break" className="timerControls__break__container">
            <h2 id="break-label" className="timerControls__time_header">Break</h2>

            <CustomButton
                key="break-decrement"
                id="break-decrement"
                title="Decrease Break Length"
                onClick={ decreaseBreak }
                className="timerControls__btn"
                content='<i class="arrow-down"></i>'
            />

            <span id="break-length" className="timerControls__time__text">
                { breakLength }
            </span>

            <CustomButton
                key="break-increment"
                id="break-increment"
                title="Increase Break Length"
                onClick={ increaseBreak }
                className="timerControls__btn"
                content='<i class="arrow-up"></i>'
            />
        </div>
    );
}

export default Break;