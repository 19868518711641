import React, { Component } from "react";
import Drumset from "./Drumset";
import drumPadArr from "./drum.json";
import "./drum.css";

class Drum extends Component {
    constructor(props) {
        super(props);

        const validKeys = [
            "Q", "W", "E",
            "A", "S", "D",
            "Z", "X", "C"
        ];

        this.state = {
            pageTitle: "Drum Machine",
            displayText: "Go!",
            validKeys
        };
    };

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyPress);
    }
    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyPress);
    }

    setDisplayText = (displayText) => {
        this.setState({ displayText });
    }

    handleKeyPress = (event) => {
        const { validKeys } = this.state;
        const { playSound, setDisplayText } = this;
        const keyPressed = event.key.toUpperCase();

        if(validKeys.includes(keyPressed)) {
            playSound(keyPressed);
            setDisplayText(keyPressed);
        }
    }

    handleOnClick = (event) => {
        event.preventDefault();
        const { playSound, setDisplayText } = this;
        const keyClicked = event.target.id;

        playSound(keyClicked);
        setDisplayText(keyClicked);
    }

    playSound = (drumpad) => {
        const sound = document.getElementById(drumpad).childNodes[1];
        sound.play();
    }

    render() {
        const { pageTitle, displayText, validKeys } = this.state;
        const { handleOnClick } = this;

        return(
            <div id="drum-machine" className="drum__container">
                <Header pageTitle={ pageTitle } />
                <Display displayText={ displayText } />
                <Drumset
                    handleOnClick={ handleOnClick }
                    validKeys={ validKeys }
                    drumPadArr={ drumPadArr }
                />
            </div>
        );
    }
}

export default Drum;

const Header = ({ pageTitle }) => {
    return (
        <div className="drum__title">
            <h1 id="drum-machine-title">
                { pageTitle }
            </h1>
        </div>
    );
};

const Display = ({ displayText }) => {
    return (
        <div id="display" className="drum__display">
            { displayText }
        </div>
    );
};