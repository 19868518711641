import React from "react";
import { useAuth0 } from "../../auth/react-auth0-spa";
import { FaLightbulb, FaRegLightbulb } from "react-icons/fa";
// import { BsGear } from "react-icons/bs";
import { FiLogIn, FiLogOut } from "react-icons/fi";

export const GreetingToggle = () => {
    const { user } = useAuth0();

    if(!user) {
        return (
            <div className="greetings__container">
                <img src="https://i2.wp.com/cdn.auth0.com/avatars/na.png?ssl=1" alt="Profile" />
            </div>
        )
    }

    if(user) {
        const { picture } = user;

        return (
            <div className="greetings__container">
                <img src={ picture } alt="Profile" />
            </div>
        )
    }
}


export const LoginToggle = () => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    if(!isAuthenticated) {
        return (
            <div className="login__icon">
                <a onClick={() => loginWithRedirect({})}>
                    <FiLogIn />
                </a>
            </div>
        );
    }

    if(isAuthenticated) {
        return (
            <div className="login__icon">
                <a onClick={() => logout()}>
                    <FiLogOut />
                </a>
            </div>
        );
    }
}


export const ModeToggle = ({ mode = "dark" }) => {
    return (
        <div className="mode__icon">
            { mode === "dark" &&
                <FaLightbulb />
            }
            { mode === "light" &&
                <FaRegLightbulb />
            }
        </div>
    );
};

/* 
export const SetupGear = () => {
    return (
        <div className="setupgear__icon">
           <BsGear />
        </div>
    )
}
*/

