import React from "react";
import Controls from "../controls/Controls";
import './displayscreen.css';

const DisplayScreen = ({
    isCountingDown,
    toggleIsCountingDown,
    reset,
    isWorkSession,
    minutes,
    seconds
}) => {
    const currentStatus = (isWorkSession) ? "Working" : "Break";
    const displayMinutes = (minutes.toString().length === 1) ? "0" + minutes : minutes
    const displaySeconds = (seconds.toString().length === 1) ? "0" + seconds : seconds

    return (
        <div className="displayScreen__container">
            <div
                id="time"
                className="displayScreen__circle"
            >
                <div id="timer-label" className="displayScreen__header">
                    { currentStatus }
                </div>
                <div id="time-left" className="displayScreen__text">
                    { displayMinutes }:{ displaySeconds }
                </div>

                <Controls
                    isCountingDown={ isCountingDown }
                    reset={ reset }
                    toggleIsCountingDown={ toggleIsCountingDown }
                />
            </div>
        </div>
    );
}

export default DisplayScreen;