import React from "react";

const DisplayBar = ({ display }) => {
    return (
        <div id="display" className="display__container">
            { display }
        </div>
    );
}

export default DisplayBar;