import React from "react";

const Drumpad = ({
    id,
    desc,
    ascii,
    soundclip,
    handleOnClick
}) => {
    return (
        <div
            onClick={ handleOnClick }
            id={ id }
            className="drum-pad"
        >
            { desc }

            <audio
                data-key={ `${id}-${ascii}` }
                id={ id }
                src={ `${soundclip}` }
                className="clip"
            />
        </div>
    );
}

export default Drumpad;