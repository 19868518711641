import React from "react";
import Drumpad from "./Drumpad";

const Drumset = ({ handleOnClick, validKeys, drumPadArr }) => {
    const drumPadKeys = validKeys.map((note) => {
        const drumpadKey = drumPadArr[note.toString()];

        return (
            <Drumpad
                key = { drumpadKey["ascii"] }
                id = { drumpadKey["id"] }
                desc = { drumpadKey["desc"] }
                ascii = { drumpadKey["ascii"] }
                soundclip = { drumpadKey["soundclip"] }
                handleOnClick={ handleOnClick }
            />
        );
    });

    return(
        <div className="drum__set__wrapper">
            { drumPadKeys }
        </div>
    );
}

export default Drumset;