import React from 'react';
import { Link } from "react-router-dom";
import './homepage.css';

export default function Homepage() {
    let appTitle = "freeCodeCamp Certification";

    return (
        <div>
            <div id="homePage">
                <h1 className="homepage-title">{appTitle}</h1>

                <h2>Responsive Web Design Certification</h2>
                <ul>
                    <li><a target="_blank" href="./tribute">Tribute Page</a></li>
                    <li><a target="_blank" href="./survey">Survey Form</a></li>
                    <li><a target="_blank" href="./landing">Product Landing Page</a></li>
                    <li><a target="_blank" href="./techdoc">Technical Documentation Page</a></li>
                    <li><a target="_blank" className="project-tile" href="./port">Personal Portfolio Webpage</a></li>
                </ul>

                <h2>JavaScript Algorithms And Data Structures Certification</h2>
                <ul>
                    <li><a target="_blank" href="./jsalgos/palindrome.html">Palindrome Checker</a></li>
                    <li><a target="_blank" href="./jsalgos/roman.html">Roman Numeral Converter</a></li>
                    <li><a target="_blank" href="./jsalgos/cipher.html">Caesars Cipher</a></li>
                    <li><a target="_blank" href="./jsalgos/telephone.html">Telephone Number Validator</a></li>
                    <li><a target="_blank" href="./jsalgos/cashregister.html">Cash Register</a></li>
                </ul>

                <h2>Front End Libraries Certification</h2>
                <ul>
                    <li>
                        <Link to={{
                            pathname: "quotes",
                            state: { fromDashboard: true }
                        }}>
                            Random Quote Machine
                        </Link>
                    </li>
                    <li>
                        <Link to={{
                            pathname: "markdown",
                            state: { fromDashboard: true }
                        }}>
                            Markdown Previewer
                        </Link>
                    </li>
                    <li>
                        <Link to={{
                            pathname: "drum",
                            state: { fromDashboard: true }
                        }}>
                            Drum Machine
                        </Link>
                    </li>
                    <li>
                        <Link to={{
                            pathname: "calc",
                            state: { fromDashboard: true }
                        }}>
                            JavaScript Calculator
                        </Link>
                    </li>
                    <li>
                        <Link to={{
                            pathname: "pomo",
                            state: { fromDashboard: true }
                        }}>
                            Pomodoro Clock
                        </Link>
                    </li>
                </ul>

                <h2>Data Visualization Certification</h2>
                <ul>
                    <li>Bar Chart</li>
                    <li>Scatterplot Graph</li>
                    <li>Heat Map</li>
                    <li>Choropleth Map</li>
                    <li>Treemap Diagram</li>
                </ul>

                <h2>Apis And Microservices Certification</h2>
                <ul>
                    <li>Timestamp Microservice</li>
                    <li>Request Header Parser Microservice</li>
                    <li>URL Shortener Microservice</li>
                    <li>Exercise Tracker</li>
                    <li>File Metadata Microservice</li>
                </ul>

                <h2>Information Security And Quality Assurance Certification</h2>
                <ul>
                    <li>Metric-Imperial Converter</li>
                    <li>Issue Tracker</li>
                    <li>Personal Library</li>
                    <li>Stock Price Checker</li>
                    <li>Anonymous Message Board</li>
                </ul>

            </div>
        </div>
    );
}