import React from "react";

const CustomButton = ({
    id,
    title,
    onClick, 
    className, 
    content,

    disabled = false
}) => {
    return (
        <button
            id={ id }
            value={ content }
            title={ title }
            onClick={ onClick }
            className={ className }
            disabled={ disabled }
            dangerouslySetInnerHTML={{ __html: content }}
        >
        </button>
    );
}

export default CustomButton;