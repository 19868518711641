import React, { Component } from "react";
import { FaTwitter, FaRetweet, FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import './quotes.css';

class Quotes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageTitle: "Random Quote Machine",
            quote: "It's not a dream, it's an adventure!",
            author: "Jake!",
            fetchUrl: "https://api.forismatic.com/api/1.0/?method=getQuote&format=json&lang=en"
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        fetch(this.state.fetchUrl)
            .then(response => response.json())
            .then((data) => {
                const quote = (data.quoteText)
                    ? data.quoteText.trim()
                    : "Unknown Quote"
                ;

                const author = (data.quoteAuthor)
                    ? data.quoteAuthor.trim()
                    : "Unknown Author"
                ;

                this.setState({
                    quote,
                    author
                });
            }).catch(err => {
                console.log(`Error in fetching data from server: ${err}`);
            });
    }

    newQuote = () => {
        this.loadData();
    }

    tweetQuote = (e) => {
        e.preventDefault();

        const { quote, author } = this.state;
        const strTweet = `"${quote}" - ${author}`;

        window.open("https://twitter.com/intent/tweet?text=" + (strTweet));
    }

    render() {
        const { quote, author } = this.state;
        const { newQuote, tweetQuote } = this;

        return (
            <div className="quote__container">
                <div id="quote-box">
                    <div className="quote__text__wrapper">
                        <div className="quote__quoted__lquote">
                            <FaQuoteLeft size="2.5em" />
                        </div>
                        <h1 id="text">{ quote }</h1>
                        <div className="quote__quoted__rquote">
                            <FaQuoteRight size="2.5em" />
                        </div>
                    </div>
                    <div id="author-info">
                        <cite>
                            <span className="author-name">-</span><div id="author">{ author }</div>
                        </cite>
                    </div>
                    <div className="quote__social__icons">
                        <div className="quote__social__icon__new">
                            <a
                                id="new-quote"
                                onClick={ newQuote }
                            >
                                <FaRetweet size="2.5em" />
                            </a>
                        </div>
                        <div className="quote__social__icon__retweet">
                            <a
                                href="https://twitter.com/intent/tweet"
                                id="tweet-quote"
                                onClick={ tweetQuote }
                            >
                                <FaTwitter size="2.5em" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Quotes;